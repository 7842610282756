import React from 'react';
import { useDispatch } from 'react-redux';
import { signOutSuccess } from '../../../redux/admin/adminSlice';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Constants from '../../../App.constants';

function Signout() {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize the useNavigate hook

    const handleSignout = async () => {
        navigate('./admin')
        try {
            const res = await fetch(Constants.API_URL+'auth/signout', {
                method: "POST",
            });
            const data = await res.json();
            if (res.ok) {
                dispatch(signOutSuccess());
                navigate('/admin'); // Redirect to the sign-in page after successful sign-out
            } else {
                console.log(data.message);
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <div>
            <button onClick={handleSignout}>Logout</button>
        </div>
    );
}

export default Signout;
