import React from 'react'
import styles from './NavbarComponent.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faHandHoldingHeart, faCube, faImages, faDollarSign, faEnvelope, faCalendar } from '@fortawesome/free-solid-svg-icons';

function NavbarComponent() {
    return (
        <>
            <div className={styles.navbar_container}>
                <ul className={styles.navbar} id="navbar">
                    <a className={styles.navbar_elt} title="About us" data-toggle="popover" data-placement="left" data-content="Content" href="#about">
                        <li>
                            <FontAwesomeIcon icon={faInfo} className={`${styles["fas"]}`} />
                        </li>
                    </a>
                    <a className={styles.navbar_elt} title="Our Equipements" data-toggle="popover" data-placement="left" data-content="Content" href="#values">
                        <li>
                            <FontAwesomeIcon icon={faHandHoldingHeart} className={`${styles["fas"]}`} />
                        </li>
                    </a>
                 
                   
                    <a className={styles.navbar_elt} title="Our prices" data-toggle="popover" data-placement="left" data-content="Content" href="#prices">
                        <li>
                            <FontAwesomeIcon icon={faDollarSign} className={`${styles["fas"]}`} />
                        </li>
                    </a>
                    <a className={styles.navbar_elt} title="Calendar" data-toggle="popover" data-placement="left" data-content="Content" href="#gallery">
                        <li>
                            <FontAwesomeIcon icon={faCalendar} className={`${styles["fas"]}`} />
                        </li>
                    </a>
                    <a className={styles.navbar_elt} title="Contact us" data-toggle="popover" data-placement="left" data-content="Content" href="#contact">
                        <li>
                            <FontAwesomeIcon icon={faEnvelope} className={`${styles["fas"]}`} />
                        </li>
                    </a>
                </ul>
            </div>
        </>
    )
}

export default NavbarComponent;