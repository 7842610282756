import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import EventModal from './EventModal'; // Import your new modal
import { createEventId } from './event-utils';
import styles from './CalendarComponent.module.css';
import Constants from '../../../App.constants';

export default function CalendarComponent() {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  // Fetch verified reservations from backend when component mounts
  useEffect(() => {
    fetchReservations();
  }, []);

  // Fetch reservations from the backend
  const fetchReservations = async () => {
    try {
      const response = await axios.get(Constants.API_URL+'reservationscalendar');
      const verifiedReservations = response.data;

      const events = verifiedReservations.map((reservation) => {
        const startDate = new Date(reservation.date);

        // If time is available, split and use it; otherwise default to midnight (00:00)
        if (reservation.time) {
          const [hours, minutes] = reservation.time.split(':').map(Number);
          startDate.setHours(hours, minutes);
        } else {
          startDate.setHours(0, 0); // Default to midnight if no time is provided
        }

        let endDate;
        if (reservation.eventType === 'half-day') {
          endDate = new Date(startDate);
          endDate.setHours(endDate.getHours() + 4); // 4-hour event for half-day
        } else if (reservation.eventType === 'hourly') {
          endDate = new Date(startDate);
          endDate.setHours(endDate.getHours() + (reservation.numberOfHours || 1)); // Default to 1-hour if undefined
        } else {
          // For daily or full-day events, check if there's an endDate or use the startDate as the endDate
          endDate = reservation.endDate ? new Date(reservation.endDate) : startDate;
        }

        return {
          id: createEventId(),
          title: reservation.eventType,
          start: startDate.toISOString(),
          end: endDate.toISOString(),
          allDay: reservation.eventType === 'daily',
        };
      });

      setCurrentEvents(events);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  // When a date is selected, open the modal
  const handleDateSelect = (selectInfo) => {
    setFormData({
      date: selectInfo.startStr,
      eventType: '',
    });
    setModalOpen(true);
  };

  // Save event to backend when form is submitted
  const handleSave = async (newEventData) => {
    try {
      // Submit the new event to the backend
      const response = await axios.post(Constants.API_URL+'reservationscalendar', newEventData);

      if (response.status === 201) {
        // Fetch updated events after saving
        fetchReservations();
      } else {
        console.error('Failed to save reservation');
      }
    } catch (error) {
      console.error('Error saving reservation:', error);
    } finally {
      setModalOpen(false); // Close the modal after save
    }
  };

  return (
    <div id='gallery' className={styles['demo-app']}>
      <div className={styles['demo-app-main']}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek',
          }}
          initialView="dayGridMonth"
          selectable={true}
          select={handleDateSelect} // Open modal on date select
          events={currentEvents}
        />
      </div>

      {/* Show the modal when modalOpen is true */}
      {modalOpen && (
        <EventModal
          show={modalOpen}
          handleClose={() => setModalOpen(false)}
          handleSave={handleSave} // Pass save handler to modal
          formData={formData} // Pass form data to modal
        />
      )}
    </div>
  );
}
