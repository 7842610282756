import React from 'react';
import styles from './ParagrapheComponent.module.css'; // Ensure this is the correct path and file name
import SectionHeaderComponent from '../home/section_header/SectionHeaderComponent.jsx';

function ParagrapheComponent({ title }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '2.5rem' }}>
      <SectionHeaderComponent title="Explorez notre large gamme de fonds de studio" />
      <div className={styles.text}>
      LIK Studio met à votre disposition une vaste sélection de fonds de studio dans une multitude
de couleurs et de textures pour donner vie à vos projets. <br /> Que vous cherchiez un fond uni,
texturé, ou un motif spécifique, nous avons ce qu'il vous faut pour créer l'ambiance parfaite.
Pour vous assurer de la <br />
disponibilité du fond de votre choix, il est nécessaire de réserver à
l'avance. Après chaque tournage, nos fonds sont soigneusement entretenus pour
<br /> garantir
leur qualité lors de votre prochaine session.

      </div>
    </div>
  );
}

export default ParagrapheComponent;
