import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Constants from '../../../App.constants';

const EventModal = ({ show, handleClose, eventTypeProp = 'defaultEventType' }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    eventType: eventTypeProp,
    date: '',
    startTime: '09:00', // Default start time
    endTime: '17:00', // Default end time
    remarks: '',
    numberOfHours: eventTypeProp === 'daily' ? 8 : 1
  });

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Reset form data when modal opens
    setFormData({
      name: '',
      phoneNumber: '',
      email: '',
      eventType: eventTypeProp,
      date: '',
      startTime: '09:00',
      endTime: '17:00',
      remarks: '',
      numberOfHours: eventTypeProp === 'daily' ? 8 : 1
    });
  }, [show, eventTypeProp]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'eventType') {
      let numberOfHours = formData.numberOfHours;
      if (value === 'hourly') {
        numberOfHours = 1;
      } else if (value === 'half-day') {
        numberOfHours = 4;
      } else if (value === 'daily') {
        numberOfHours = 8;
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        numberOfHours: numberOfHours
      }));
    } else if (name === 'startTime' || name === 'endTime') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(Constants.API_URL+'reservations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        setSubmitted(true);
        setFormData({
          name: '',
          phoneNumber: '',
          email: '',
          eventType: eventTypeProp,
          date: '',
          startTime: '09:00',
          endTime: '17:00',
          remarks: '',
          numberOfHours: eventTypeProp === 'daily' ? 8 : 1
        });
        setTimeout(() => {
          setSubmitted(false);
        }, 2000);
      } else {
        console.error('Échec de la soumission du formulaire');
      }
    } catch (error) {
      console.error('Erreur :', error);
    }
  };

  const isTimeValid = (time) => {
    const [hours] = time.split(':').map(Number);
    return hours >= 9 && hours <= 17;
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Réserver Votre Séance</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" name="name" value={formData.name} onChange={handleChange} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Numéro de Téléphone</Form.Label>
            <Form.Control type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Date</Form.Label>
            <Form.Control type="date" name="date" value={formData.date} onChange={handleChange} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Control as="select" name="eventType" value={formData.eventType} onChange={handleChange} required>
              <option value="hourly">Horaire</option>
              <option value="half-day">Demi-Journée</option>
              <option value="daily">Journée Complète</option>
            </Form.Control>
          </Form.Group>
          {(formData.eventType === 'hourly' || formData.eventType === 'half-day' || formData.eventType === 'daily') && (
            <>
              <Form.Group>
                <Form.Label>Heure de Début</Form.Label>
                <Form.Control
                  type="time"
                  name="startTime"
                  value={formData.startTime}
                  onChange={handleChange}
                  min="09:00"
                  max="17:00"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Heure de Fin</Form.Label>
                <Form.Control
                  type="time"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  min="09:00"
                  max="17:00"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Nombre d'Heures</Form.Label>
                <Form.Control
                  type="number"
                  name="numberOfHours"
                  value={formData.numberOfHours}
                  onChange={handleChange}
                  min={1}
                  max={formData.eventType === 'daily' ? 8 : 4} // Limit max hours based on event type
                  required
                />
              </Form.Group>
            </>
          )}
          <Form.Group>
            <Form.Label>Remarques</Form.Label>
            <Form.Control as="textarea" rows={3} name="remarks" value={formData.remarks} onChange={handleChange} />
          </Form.Group>
          <Button variant="primary" type="submit">Enregistrer</Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Fermer</Button>
      </Modal.Footer>
      {submitted && (
        <div className="alert alert-success" role="alert">Réservation soumise avec succès !</div>
      )}
    </Modal>
  );
};

export default EventModal;
