import React, { useEffect } from 'react';
import FooterComponent from '../home/footer/FooterComponent';
import NavbarComponent from '../home/navbar/NavbarComponent';
import ContactComponent from '../home/contact/ContactComponent';
import CalendarComponent from './calendar/CalendarComponent';
import HerosectionComponent from '../home/hero_section/HerosectionComponent';
import TarifsComponent from './tarifs/TarifsComponent';
import AboutComponent from './about/AboutComponent';
import AboutStudioComponent from './aboutStudio/AboutStudioComponent';
import IconsComponent from '../add-icons/IconsComponent';
import ParagraphComponent from '../paragraph/ParagraphComponent';
import ParagrapheComponent from '../paragraph2/ParagrapheComponent';
function HomeComponent() {
    // useEffect(() => {
    //     const about = document.getElementById('about')
    //     const values = document.getElementById('values')
    //     const materiel = document.getElementById('materiel')
    //     const gallery = document.getElementById('gallery')
    //     const prices = document.getElementById('prices')
    //     const contact = document.getElementById('contact')

    //     const handleScroll = () => {
    //         var scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop;
    //         if ((scrollTopPosition + 500) > about.offsetTop && (scrollTopPosition + 500) < values.offsetTop) {
    //             removeActiveFromOthersThan(0);
    //             (document.getElementById("navbar")).children[0].classList.add('active');
    //         }
    //         if ((scrollTopPosition + 500) > values.offsetTop && (scrollTopPosition + 500) < materiel.offsetTop) {
    //             removeActiveFromOthersThan(1);
    //             (document.getElementById("navbar")).children[1].classList.add('active');
    //         }
    //         if ((scrollTopPosition + 500) > materiel.offsetTop && (scrollTopPosition + 500) < gallery.offsetTop) {
    //             removeActiveFromOthersThan(2);
    //             (document.getElementById("navbar")).children[2].classList.add('active');
    //         }
    //         if ((scrollTopPosition + 500) > gallery.offsetTop && (scrollTopPosition + 500) < prices.offsetTop) {
    //             removeActiveFromOthersThan(3);
    //             (document.getElementById("navbar")).children[3].classList.add('active');
    //         }
    //         if ((scrollTopPosition + 500) > prices.offsetTop && (scrollTopPosition + 500) < contact.offsetTop) {
    //             removeActiveFromOthersThan(4);
    //             (document.getElementById("navbar")).children[4].classList.add('active');
    //         }
    //         if ((scrollTopPosition + 500) > contact.offsetTop) {
    //             removeActiveFromOthersThan(5);
    //             (document.getElementById("navbar")).children[5].classList.add('active');
    //         }
    //         if ((scrollTopPosition + 500) < about.offsetTop) {
    //             removeActiveFromOthersThan(6);
    //         }
    //     };

    //     const removeActiveFromOthersThan = (index) => {
    //         const navbar_children = document.getElementById("navbar").children;
    //         for (let i = 0; i < navbar_children.length; i++) {
    //             if (i != index) {
    //                 navbar_children[i].classList.remove('active');
    //             }
    //         }

    //     }
    //     window.addEventListener('scroll', handleScroll);
    //     return () => { window.removeEventListener('scroll', handleScroll); };
    // }, []);


    return (
        <>
            <header>
                <NavbarComponent />
            </header>
            {/* Hero_section*/}
            <HerosectionComponent />
            {/* About*/}
             <AboutComponent /> 
              {/* About*/}
              <IconsComponent/>
              <AboutStudioComponent/> 
             <ParagraphComponent/>           
             <TarifsComponent /> 
            {/* Calendar */}
            <CalendarComponent/>
            <ParagrapheComponent/>
            {/* Contact */}
            <ContactComponent />

            {/* Footer */}
            <FooterComponent /> 
        </>
    );
}

export default HomeComponent;
