// ReservationComponent.js
import React, { useState, useEffect } from 'react';
import SidebarComponent from '../sidebar/SidebarComponent';
import axios from 'axios';
import styles from './ReservationComponent.module.css'
import Constants from '../../../App.constants';
function ReservationComponent() {
    const [reservations, setReservations] = useState([]);
    const [filters, setFilters] = useState({
        name: '',
        date: '',
        time: '',
        email: '',
        phoneNumber: '',
        verified: ''
    });
    const [showModifyModal, setShowModifyModal] = useState(false);
    const [selectedReservation, setSelectedReservation] = useState(null);

    useEffect(() => {
        fetchReservations();
    }, []);

    const fetchReservations = async () => {
        try {
            const response = await axios.get(Constants.API_URL+'reservations');
            setReservations(response.data);
        } catch (error) {
            console.error('Error fetching reservations:', error);
        }
    };

    const confirmReservation = async (reservationId) => {
        try {
            await axios.put(Constants.API_URL+`reservations/${reservationId}/confirm`);
            fetchReservations(); // Refresh reservations after confirmation
        } catch (error) {
            console.error('Error confirming reservation:', error);
        }
    };

    const deleteReservation = async (reservationId) => {
        try {
            await axios.delete(Constants.API_URL+`reservations/${reservationId}`);
            fetchReservations(); // Refresh reservations after deletion
        } catch (error) {
            console.error('Error deleting reservation:', error);
        }
    };

    const applyFilters = () => {
        let filteredReservations = [...reservations];

        if (filters.name !== '') {
            filteredReservations = filteredReservations.filter(reservation => reservation.name.toLowerCase().includes(filters.name.toLowerCase()));
        }
        if (filters.date !== '') {
            filteredReservations = filteredReservations.filter(reservation => reservation.date.includes(filters.date));
        }
        if (filters.time !== '') {
            filteredReservations = filteredReservations.filter(reservation => reservation.time.includes(filters.time));
        }
        if (filters.email !== '') {
            filteredReservations = filteredReservations.filter(reservation => reservation.email.toLowerCase().includes(filters.email.toLowerCase()));
        }
        if (filters.phoneNumber !== '') {
            filteredReservations = filteredReservations.filter(reservation => reservation.phoneNumber.includes(filters.phoneNumber));
        }
        if (filters.verified !== '') {
            const verifiedValue = parseInt(filters.verified);
            filteredReservations = filteredReservations.filter(reservation => reservation.verified === verifiedValue);
        }

        return filteredReservations;
    };

    const handleFilterChange = (key, value) => {
        setFilters({
            ...filters,
            [key]: value
        });
    };
    const handleModifyChange = (field, value) => {
        setSelectedReservation(prevReservation => ({
            ...prevReservation,
            [field]: value
        }));
    };
    
    const openModifyModal = (reservation) => {
        setSelectedReservation(reservation);
        setShowModifyModal(true);
    };

    const closeModifyModal = () => {
        setSelectedReservation(null);
        setShowModifyModal(false);
    };

    const handleSubmitModifyReservation = async () => {
        try {
            await axios.put(Constants.API_URL+`reservations/${selectedReservation._id}`, selectedReservation);
            fetchReservations(); // Refresh reservations after modification
            closeModifyModal();
        } catch (error) {
            console.error('Error modifying reservation:', error);
        }
    };
    
    return (
        <div className='row d-flex bg-white' style={{ height: '100vh' }}>
            <div className="h-100 col-md-4 col-lg-3 col-xl-2">
                <SidebarComponent />
            </div>
            <div className="h-100 col-md-8 col-lg-9 col-xl-10">
                <div className="container mt-5 px-2">
                    <div className="table-responsive">
                       <div style={{display:"flex",gap:'10px',flexWrap:'wrap'}}>
                        <div className="mb-3">
                            <input type="text" placeholder="Name" value={filters.name} onChange={(e) => handleFilterChange('name', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <input type="text" placeholder="Date" value={filters.date} onChange={(e) => handleFilterChange('date', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <input type="text" placeholder="Time" value={filters.time} onChange={(e) => handleFilterChange('time', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <input type="text" placeholder="Email" value={filters.email} onChange={(e) => handleFilterChange('email', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <input type="text" placeholder="Phone Number" value={filters.phoneNumber} onChange={(e) => handleFilterChange('phoneNumber', e.target.value)} />
                        </div>
                        <div className="mb-3">
                            <input type="text" placeholder="Verified" value={filters.verified} onChange={(e) => handleFilterChange('verified', e.target.value)} />
                        </div>
                        </div>
                        <table className="table table-responsive">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Phone Number</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">EventType</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">remarques</th>

                                    <th scope="col">Number of Houers</th>
                                    <th scope="col">verified</th>
                                    <th scope="col"> Action</th>


                                </tr>
                            </thead>
                            <tbody>
                                {applyFilters().map((reservation, index) => (
                                    <tr key={index}>
                                        <td>{index}</td>
                                        <td>{reservation.date}</td>
                                        <td>{reservation.name}</td>
                                        <td>{reservation.phoneNumber}</td>
                                        <td>{reservation.email}</td>
                                        <td>{reservation.eventType}</td>
                                        <td>{reservation.time}</td>
                                        <td>{reservation.remarks}</td>
                                        <td>{reservation.numberOfHours}</td>
                                        <td>{reservation.verified}</td>


                                        <td>
                                            <button className="btn btn-danger btn-sm" onClick={() => deleteReservation(reservation._id)}>Delete</button>
                                            <button className="btn btn-modifieri btn-sm" onClick={() => openModifyModal(reservation)}>Modifier</button>

                                            {reservation.verified === 0 ? (
                                            <button className="btn btn-success btn-sm" onClick={() => confirmReservation(reservation._id)}>Verifier</button>
                                        ) : null}

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {showModifyModal && selectedReservation && (
    <div className="modal" style={{ display: showModifyModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
                <div className={`${styles["modal-header"]}`}>
                    <h5 className="modal-title">Modify Reservation</h5>
                    <button type="button" className={`${styles["close"]}`} onClick={closeModifyModal}>
                        <span>&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                <form onSubmit={handleSubmitModifyReservation}>
    <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input type="text" id="name" className="form-control" value={selectedReservation.name} onChange={(e) => handleModifyChange('name', e.target.value)} />
    </div>
    <div className="form-group">
        <label htmlFor="date">Date:</label>
        <input type="text" id="date" className="form-control" value={selectedReservation.date} onChange={(e) => handleModifyChange('date', e.target.value)} />
    </div>
    <div className="form-group">
        <label htmlFor="time">Time:</label>
        <input type="text" id="time" className="form-control" value={selectedReservation.time} onChange={(e) => handleModifyChange('time', e.target.value)} />
    </div>
    <div className="form-group">
        <label htmlFor="phoneNumber">Phone Number:</label>
        <input type="text" id="phoneNumber" className="form-control" value={selectedReservation.phoneNumber} onChange={(e) => handleModifyChange('phoneNumber', e.target.value)} />
    </div>
    <div className="form-group">
        <label htmlFor="email">Email:</label>
        <input type="email" id="email" className="form-control" value={selectedReservation.email} onChange={(e) => handleModifyChange('email', e.target.value)} />
    </div>
    <div className="form-group">
        <label htmlFor="eventType">Event Type:</label>
        <input type="text" id="eventType" className="form-control" value={selectedReservation.eventType} onChange={(e) => handleModifyChange('eventType', e.target.value)} />
    </div>
    <div className="form-group">
        <label htmlFor="remarks">Remarks:</label>
        <input type="text" id="remarks" className="form-control" value={selectedReservation.remarks} onChange={(e) => handleModifyChange('remarks', e.target.value)} />
    </div>
    <div className="form-group">
        <label htmlFor="numberOfHours">Number of Hours:</label>
        <input type="text" id="numberOfHours" className="form-control" value={selectedReservation.numberOfHours} onChange={(e) => handleModifyChange('numberOfHours', e.target.value)} />
    </div>
    <div className="form-group">
        <label htmlFor="verified">Verified:</label>
        <input type="text" id="verified" className="form-control" value={selectedReservation.verified} onChange={(e) => handleModifyChange('verified', e.target.value)} />
    </div>
    <button type="submit" className="btn btn-primary">Save Changes</button>
</form>

                </div>
            </div>
        </div>
    </div>
)}



                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReservationComponent;
