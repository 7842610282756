import React, { useState, useEffect } from 'react';
import SidebarComponent from '../sidebar/SidebarComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import Constants from '../../../App.constants';

function GalleryComponent() {
    const [contacts, setContacts] = useState([]);

    // Fetch contacts from the backend when the component mounts
    useEffect(() => {
        fetchContacts();
    }, []);

    // Function to fetch contacts from the backend API
    const fetchContacts = async () => {
        try {
            const response = await fetch(Constants.API_URL+'contacts');
            if (response.ok) {
                const data = await response.json();
                setContacts(data); // Update state with fetched contacts
            } else {
                console.error('Failed to fetch contacts');
            }
        } catch (error) {
            console.error('Error fetching contacts:', error.message);
        }
    };

    // Function to delete a contact by ID
    const deleteContact = async (id) => {
        try {
            const response = await fetch(Constants.API_URL+`contact/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                // Filter out the deleted contact from the state
                setContacts(contacts.filter(contact => contact._id !== id));
            } else {
                console.error('Failed to delete contact');
            }
        } catch (error) {
            console.error('Error deleting contact:', error.message);
        }
    };

    return (
        <div className='row d-flex bg-white' style={{ height: '100vh' }}>
            <div className="h-100 col-md-4 col-lg-3 col-xl-2">
                <SidebarComponent />
            </div>
            <div className="h-100 col-md-8 col-lg-9 col-xl-10">
                <div className="container mt-5 px-2">
                    <div className="table-responsive">
                        <table className="table table-responsive">
                            <thead className="bg-light">
                                <tr>
                                    <th scope="col" width="5%">#</th>
                                    <th scope="col" width="10%">Name</th>
                                    <th scope="col" width="15%">Email</th>
                                    <th scope="col" width="15%">Phone Number</th>
                                    <th scope="col" width="35%">Description</th>
                                    <th scope="col" width="10%">ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contacts.map((contact, index) => (
                                    <tr key={contact._id} className="align-middle">
                                        <td>{index + 1}</td>
                                        <td>{contact.name}</td>
                                        <td>{contact.email}</td>
                                        <td>{contact.phone}</td>
                                        <td>{contact.message}</td>
                                        <td>
                                            <button className="btn btn-danger" onClick={() => deleteContact(contact._id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GalleryComponent;
