import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signInFailure, signInStart, signInSuccess } from '../../redux/admin/adminSlice';
import './Signin.css';
import Constants from '../../App.constants';

const Signin = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const dispatch = useDispatch();
  const { loading, error: errorMessage } = useSelector((state) => state.admin);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(signInFailure(null));
  }, [dispatch]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.password) {
      return dispatch(signInFailure('Please fill all fields'));
    }

    try {
      dispatch(signInStart());
      const res = await fetch(Constants.API_URL+'auth/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await res.json();

      if (!res.ok) {
        return dispatch(signInFailure(data.message));
      }

      dispatch(signInSuccess(data));
      navigate('/admin/reservation');
    } catch (error) {
      dispatch(signInFailure(error.message));
    }
  };

  return (
    <div className='containerpagesignin'>
      <div className='containerform'>
        <div className='minicontainerform'>
          <h1 className="text-3xl font-semibold pb-10" style={{ color: 'white' }}>Welcome back</h1>
          <div className='form'>
            <form onSubmit={handleSubmit}>
              <label>
                <input
                  className="input rounded-xl"
                  type="email"
                  placeholder="Email"
                  required
                  id='email'
                  onChange={handleChange}
                />
              </label>
              <label>
                <input
                  className="input rounded-xl"
                  type="password"
                  placeholder="Password"
                  required
                  id='password'
                  onChange={handleChange}
                />
              </label>
              <div className='containerinfosupp'>
             
                <div className='containerbtnsignup'>
                  <button type='submit' id='btnsignup'>
                    <span>{loading ? 'Loading...' : 'Signin'}</span>
                  </button>
                </div>
              </div>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
