import React from 'react';
import styles from './FooterComponent.module.css'; // adjust the path as necessary
import LogoBlack from '../../../assets/LogoBlack.png';
import Lens from '../../../assets/Lens.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
    <footer>
        <div className={styles["row"]}>
            <div className={styles["column"]}>
                <h2><b>À Propos LIK Studio</b></h2>
                <p>
                Un studio où vos idées deviennent des œuvres visuelles remarquables.
                </p>
            </div>
            <div className={styles["column2"]}>
                <h5><span><b>Capturez</b>
                    <br /><b>Vos </b> <b>Meilleurs</b>
                    <br /> <b>Moments</b></span>
                </h5>
            </div>
            <div className={styles["column1"]}>
                <div>
                    <img src={LogoBlack} className={styles['Logo']} alt="" />
                    <div className={`${styles["social-icons"]}`}>
                        <a href="https://www.instagram.com/lik.studio.tn?igsh=MzNzNHoxYnhjaWsw" target="_blank">
                            <FontAwesomeIcon icon={faInstagram} className={`${styles["fab"]}`} />
                        </a>
                       
                    </div>
                </div>
            </div>
        </div>
    </footer>
)
export default Footer;