import React, { useState } from 'react';
import styles from './TarifsComponent.module.css';
import SectionHeaderComponent from '../section_header/SectionHeaderComponent';
import EventModal from './EventModal'; 

function TarifsComponent() {
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [selectedEventType, setSelectedEventType] = useState(''); // State to hold the selected event type

  // Function to open the modal and set the selected event type
  const openModal = (eventType) => {
    setSelectedEventType(eventType);
    setShowModal(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div id='prices' style={{ display: 'flex', flexDirection: 'column', padding:'2.5rem' }}>     
      <SectionHeaderComponent title="NOS TARIFS" />
     
      <div className={styles.grid}>
        <div className={styles.card}>
          <h2 className={styles.card_title}>Réservation de <br />2 heures</h2>
          <hr />
          <p className={styles.time}>2 Heures</p>
          <p className={styles.pricing}>150DT</p>
          
          <a onClick={() => openModal('hourly')} className={styles.cta_btn}>Réserver Maintenant</a>
        </div>
        <div className={styles.card}>
          <h2 className={styles.card_title}>Réservation de <br />4 heures</h2>
          <hr />
          <p className={styles.time}>4 Heures</p>
          <p className={styles.pricing}>250DT</p>
          
          <a onClick={() => openModal('hourly')} className={styles.cta_btn}>Réserver Maintenant</a>
        </div>
        <div className={styles.card}>
          <h2 className={styles.card_title}>Réservation <br /> journée complète
          </h2>
          <hr />
          <p className={styles.time}>8 Heures</p>
          <p className={styles.pricing}>400DT</p>
          <a onClick={() => openModal('hourly')} className={styles.cta_btn}>Réserver Maintenant</a>
        </div>
        <EventModal show={showModal} handleClose={closeModal} eventTypeProp={selectedEventType} />
      </div>
    </div>
  );
}

export default TarifsComponent;
