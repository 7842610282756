import React from 'react';
import styles from './ParagraphComponent.module.css'; // Ensure this is the correct path and file name
import SectionHeaderComponent from '../home/section_header/SectionHeaderComponent';

function ParagraphComponent({ title }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '2.5rem' }}>
      <SectionHeaderComponent title="Complétez votre expérience avec nos packs de location" />
      <div className={styles.text}>
        Pour répondre à tous vos besoins créatifs, nous offrons des packs de location de matériel de
        vidéographie et de photographie, disponibles sur réservation. <br />
        Nos équipements incluent des caméras haute définition, des systèmes d'éclairage professionnels,
        ainsi que du matériel audio de haute qualité pour garantir <br />
        des productions au rendu impeccable.
        Que vous ayez besoin de trépieds, de micros, ou de dispositifs de contrôle de la lumière, nos packs
        sont conçus pour <br />
         vous fournir tout le nécessaire, vous permettant ainsi de vous concentrer sur la
        création. Réservez à l'avance pour vous assurer d'avoir le matériel adéquat  <br />
        lors de vos séances
        au LIK Studio.
      </div>
      
    </div>
  );
}

export default ParagraphComponent;
