import React from 'react';
import styles from './AboutComponent.module.css';
import SectionHeaderComponent from '../section_header/SectionHeaderComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import studio from '../../../assets/studio.png';

function AboutComponent() {
    return (
        <section id='about' className={`${styles["about-section"]}`} style={{ minHeight: "60vh",display:"flex",justifyContent:'center',alignContent:"center" }} >
            <div className="p-5 text-sm-center text-md-start">
                <div className={` row ${styles["clearfix"]}`}>
                    {/* Content Column */}
                    <div className={`${styles["content-column"]} col-md-6 col-sm-12 col-xs-12`}>
                        <div className={`${styles["inner-column"]}`}>
                            <div className={`${styles["sec-title"]}`}>
                                <SectionHeaderComponent title="À PROPOS DE NOUS" />
                                <h2 style={{ color: "white" }}>
                                      <br /><b>LIK Studio</b> 
                                </h2>
                            </div>
                            <div className={`${styles["text"]}`}>
                            À seulement 7 km du centre-ville de Sfax, LIK Studio met à votre disposition un
                            espace modulable de 48 mètres carrés, ouvert 7 jours sur 7. Conçu pour répondre à
                            un large éventail de besoins créatifs, notre studio est équipé d'un cyclorama, de
                            fonds variés (wallpaper) et d'un green screen. Pour compléter votre expérience, nous
                            proposons également des packs de location de matériel de vidéographie et de
                            photographie, vous offrant tout le nécessaire pour vos productions. Que vous soyez
                            photographe, vidéaste, ou créateur de contenu, LIK Studio vous offre un
                            environnement professionnel et inspirant pour réaliser vos projets avec une touche
                            artistique unique.

                            </div>
                            <div className={`${styles["email"]}`} style={{ display: 'none ' }}>
                                Request Quote:{" "}
                                <span className={`${styles["theme_color"]}`}>freequote@gmail.com</span>
                            </div>
                            <a href="about.html" className={`${styles["theme-btn"]} ${styles["btn-style-three"]}`} style={{ display: 'none ' }}>
                                Read More
                            </a>
                        </div>
                    </div>
                    {/* Image Column */}
                    <div className="col-md-2"></div>
                    <div className={`${styles["image-column"]} col-md-4 col-sm-12 "col-xs-12`}>
                        <div
                            className={`${styles["inner-column"]} `}
                            data-wow-delay="0ms"
                            data-wow-duration="1500ms"
                        >
                            <div className={`${styles["image"]}`}>
                                <img src={studio} alt="description of the image" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutComponent;
