import React, { useState } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import styles from './AboutStudioComponent.module.css'; // Import the CSS module
import archi from '../../../assets/archi.png';

const AboutStudioComponent = () => {
  const [activeTab, setActiveTab] = useState('Tab1');

  const renderContent = () => {
    switch (activeTab) {
      case 'Tab1':
    return (
        <ul>
            <li>Grand cyclorama (5 m x 4 m x 3,5 m)</li>
            <li>Espace de détente</li>
            <li>Accessible aux fauteuils roulants</li>
        </ul>
    );

      case 'Tab2':
    return (
        <ul>
            <li>Salle de bain/toilettes, douche</li>
            <li>Accès au vestiaire</li>
            <li>Télévision et salons dans la zone de détente</li>
            <li>Nombreux parkings gratuits
              <li>Accès gratuit au WI-FI</li>
              <li>Climatisation chaud et froid</li>
            </li>
        </ul>
    );

      default:
        return <p>Select a tab to see the content.</p>;
    }
  };

  return (
    <Container id='values' className={styles.aboutStudioContainer}>
      <Row>
        <Col md={6}>
        <img
          src={archi}
          alt="Studio"
          className={`img-fluid ${styles.aboutStudioImage}`}
        />
        </Col>
        <Col md={6}>
          <Nav
            variant="tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className={styles.aboutStudioTabs}
          >
            <Nav.Item>
              <Nav.Link eventKey="Tab1">Caractéristiques du Studio</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Tab2">Équipements du Studio</Nav.Link>
            </Nav.Item>
             
          </Nav>
          <div className={styles.aboutStudioContent}>
            {renderContent()}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutStudioComponent;
