import React from 'react';
// Importing icons from assets folder
import panoramique from '../../assets/panoramique.png';
import backdrop from '../../assets/backdrop.png';
import media from '../../assets/media.png';
import location from '../../assets/location.png';

const IconsComponent = () => {
  return (
    <div style={styles.container}>
      <div style={styles.feature}>
        <img src={panoramique} alt="Large Cyclorama" style={styles.image} />
        <p style={styles.text}>Grand Cyclorama</p>
      </div>
      <div style={styles.feature}>
        <img src={backdrop} alt="Backdrop Wall & Props" style={styles.image} />
        <p style={styles.text}>Mur de fond et accessoires</p>
      </div>
      <div style={styles.feature}>
        <img src={media} alt="Designed for Photo & Video" style={styles.image} />
        <p style={styles.text}>Designé pour Photos & Videos</p>
      </div>
      <div style={styles.feature}>
        <img src={location} alt="5km to CBD Brooklyn Park" style={styles.image} />
        <p style={styles.text}>7 km du centre-ville de Sfax</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap', // Allows items to wrap on smaller screens
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#f8f8f8',
  },
  feature: {
    textAlign: 'center',
    color: '#000',
    margin: '10px', // Add some spacing between items
    flex: '1 1 200px', // Flex-grow, flex-shrink, and basis
    maxWidth: '300px', // Maximum width of each feature
  },
  image: {
    width: '100%', // Make image responsive
    maxWidth: '80px',
    height: 'auto',
  },
  text: {
    marginTop: '10px', // Add space between image and text
    fontSize: '1rem',
    textAlign: 'center',
  },
};

export default IconsComponent;
