import React, { useState } from 'react';
import styles from './ContactComponent.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import Constants from '../../../App.constants';

function ContactComponent() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
      });
    
      const [submitted, setSubmitted] = useState(false);
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch(Constants.API_URL+'contact', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          if (response.ok) {
            setSubmitted(true);
            setFormData({ name: '', email: '', phone: '', message: '' });
            setTimeout(() => {
                setSubmitted(false);
            }, 2000);
          } else {
            console.error('Failed to submit form');
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    
    return (
        <div id='contact' className={`${styles["container1"]}`}>
        <div className={`${styles["container"]}`} id="contact">
            <span className={`${styles["big-circle"]}`} />
            <img src="img/shape.png" className={`${styles["square"]}`} alt="" />
            <div className={`${styles["form"]}`}>
                <div className={`${styles["contact-info"]}`}>
                    <h2 className={`${styles["title"]}`}>Restons en contact</h2><br />
                    <p className={`${styles["text"]}`}>
                    Votre satisfaction est notre priorité, contactez-nous !
                    </p>
                    <br />
                  
                    <div className={`${styles["info"]}`}>
                        <div className={`${styles["information"]}`}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} className={`${styles["fas"]}`} /> &nbsp; &nbsp;
                            <p>Sfax, Tunisie</p>
                        </div>
                        <div className={`${styles["information"]}`}>
                            <FontAwesomeIcon icon={faEnvelope} className={`${styles["fas"]}`} /> &nbsp; &nbsp;
                            <p>lik.tnproduction@gmail.com</p>
                        </div>
                        <div className={`${styles["information"]}`}>
                            <FontAwesomeIcon icon={faPhone} className={`${styles["fas"]}`} />
                            &nbsp;&nbsp;
                            <p>53 130 310</p>
                        </div>
                    </div>
                   <br />
                   <br />
                   <br />
                   <br />
                   <br />
                   <br />
                   <br /><br />
                    <div className={`${styles["social-media"]}`}>
                        
                        <div className={`${styles["social-icons"]}`}>
                            
                          
                        <a href="https://www.instagram.com/lik.studio.tn?igsh=MzNzNHoxYnhjaWsw" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} className={`${styles["fab"]}`} />
                            </a>
                           
                        </div>
                    </div>
                </div>
                <div className={`${styles["contact-form"]}`}>
                    <span className={`${styles["circle"]} ${styles["one"]}`} />
                    <span className={`${styles["circle"]} ${styles["two"]}`} />
                   
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <h2 className={`${styles["title"]}`}>Contactez Nous</h2>
                        <div className={`${styles["input-container"]}`}>
                            <input type="text" name="name" className={`${styles["input"]}`} placeholder='Nom et Prénom'   value={formData.name}
                  onChange={handleChange}
                  required/>
                        </div>
                        <div className={`${styles["input-container"]}`}>
                            <input type="email" name="email" className={`${styles["input"]}`} placeholder="Email"   value={formData.email}
                  onChange={handleChange}
                  required />
                        </div>
                        <div className={`${styles["input-container"]}`}>
                            <input type="tel" name="phone" className={`${styles["input"]}`} placeholder="Num de Téléphone" value={formData.phone}
                  onChange={handleChange}
                  required />
                        </div>
                        <div className={`${styles["input-container"]} ${styles["textarea"]}`}>
                            <textarea name="message" className={`${styles["input"]}`} defaultValue={""} placeholder="Message"    value={formData.message}
                  onChange={handleChange}
                  required/>
                        </div>
                        <input type="submit" Value="Send" className={`${styles["btn"]}`} />
                    </form>
                    {submitted ? (
                        <div>Votre message a été envoyé avec succès !</div>
                    ) : ( <div> </div>)}
                </div>
            </div>
       </div>
       <div style={{ display: 'flex', justifyContent: 'center',zIndex:'4000',position:'relative'}}>
       <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3276.50046075273!2d10.788329475550428!3d34.793348972887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1301d3542084d445%3A0x1eeb3374bc82e309!2sLik%20studio!5e0!3m2!1sfr!2stn!4v1726760359326!5m2!1sfr!2stn"
  width="70%"
  height="300px"
  style={{ border: 0, borderRadius: '20px', padding: '30px', zIndex: '5' }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
></iframe>

    </div>  
       </div>
    );
};

export default ContactComponent;