import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

import HomeComponent from './components/home/HomeComponent';
import ReservationComponent from './components/admin-dashboard/reservation/ReservationComponent';
import GalleryComponent from './components/admin-dashboard/gallery/GalleryComponent';
import ContactComponent from './components/admin-dashboard/contact/ContactComponent';
import Signin from './components/signin/Signin';
import Signout from './components/admin-dashboard/Signout/Signout';

import './App.css';
import AboutComponent from './components/home/about/AboutComponent';
import AboutStudioComponent from './components/home/aboutStudio/AboutStudioComponent';
import TarifsComponent from './components/home/tarifs/TarifsComponent';
import CalendarComponent from './components/home/calendar/CalendarComponent';

function App() {
    const { currentAdmin } = useSelector(state => state.admin);

    const isAdminLoggedIn = () => {
        return currentAdmin !== null;
    };

    const ProtectedRoute = ({ children }) => {
        return isAdminLoggedIn() ? children : <Navigate to="/admin" />;
    };

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/admin" element={<Signin />} />
                    <Route path="/" element={<HomeComponent />} />
                    <Route path="/about" element={<AboutComponent />} />
                    <Route path="/studio" element={<AboutStudioComponent />} />
                    <Route path="/prices" element={<TarifsComponent />} />
                    <Route path="/calendar" element={<CalendarComponent />} />
                    <Route path="/contact" element={<ContactComponent />} />

                    <Route
                        path="/admin/reservation"
                        element={
                            <ProtectedRoute>
                                <ReservationComponent />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/admins"
                        element={
                            <ProtectedRoute>
                                <GalleryComponent />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/contact"
                        element={
                            <ProtectedRoute>
                                <ContactComponent />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/signout"
                        element={
                            <ProtectedRoute>
                                <Signout />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
