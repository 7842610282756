import React, { useEffect } from 'react';
import styles from './HerosectionComponent.module.css';
import mainpic from '../../../assets/mainpic.jpg';

function HerosectionComponent() {
    return (
        <>
            <div className={`${styles["section-parallax"]} ${styles["parallax-00"]}`} style={{ backgroundImage: "url('http://www.ivang-design.com/svg-load/parallax/parallax-0.png')" }}></div>
            <div className={`${styles["section-parallax"]} ${styles["parallax-01"]}`} style={{ backgroundImage: "url('http://www.ivang-design.com/svg-load/parallax/parallax-01.png')" }}></div>

            <div className={`${styles["section"]} ${styles["half-height"]}`}>
                <div className={styles["section-title-wrap"]}>
                    <div className={`${styles["container"]} ${styles["row"]}`}>
                        <div className={`col-12 text-center ${styles["parallax-fade-top"]}`}>
                            <p className='text-center'>We do art</p>
                            <h1>
                            LIVE THE INSPIRATION AND KNOWLEDGE
                            </h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles["section"]} ${styles["full-height"]} ${styles["z-bigger"]}`}>
                <ul className={styles["case-study-images"]}>
                    <li>
                    <div className={styles["img-hero-background"]}>
    <img src={mainpic} alt="Hero Background" className={styles.heroImage} />
</div>
                    </li>
                </ul>
            </div >
        </>
    );
}

export default HerosectionComponent;
