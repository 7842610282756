import React from 'react'
import styles from './SectionHeaderComponent.module.css'

export default function SectionHeaderComponent({ title }) {
    return (
        <div className={styles['wrapper-left']}> {/* using styles from imported module */}
            <h1 className={styles.head}>{title}</h1> {/* using styles from imported module */}
        </div>
    )
}
